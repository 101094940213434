/** @jsxImportSource @emotion/react */

import SEO from "../../components/old/seo";
import Footer from "../../components/shared/Footer/Footer";
import CaseStudy from "./components/CaseStudy/CaseStudy";
import Title from "./components/Title/Title";
import { l_projects } from "./locales";

const Projects = () => {
  return (
    <>
      <SEO
        title={l_projects("seo.title")}
        description={l_projects("seo.description")}
      />

      <Title />
      <CaseStudy />
      <Footer />
    </>
  );
};

export default Projects;
